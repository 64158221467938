import rebound from 'rebound';

export default class Particle {

  // constructor(ctx, sides, canvasWidth, canvasHeight) {
  constructor(ctx, config, sides, springSystem) {
    this.ctx = ctx;
    this.sides = sides;
    this.life = 1000;  // TODO
    this.size = Math.floor(Math.random() * 20) + 15;

    this.color = Math.floor(Math.random() * config.particles.fill.length);

    this.pos = 0;
    // this.position = {x: 0, y: 0}
    this.attractTo = {x: 0, y: 0}
    this.seedX = Math.random();
    this.seedY = Math.random();
    // this.canvasWidth = 0;
    // this.canvasHeight = 0;

    this.config = config;

    this.onSpringAtRest = this.onSpringAtRest.bind(this);
    this.onSpringUpdate = this.onSpringUpdate.bind(this);

    this.spring = springSystem.createSpring(config.spring.tension + (this.seedX * config.spring.randomTension), config.spring.friction + (this.seedY * config.spring.randomFriction));
    this.spring.addListener({
      onSpringUpdate: this.onSpringUpdate,
      // onSpringAtRest: this.onSpringAtRest
    });
  }

  destroy() {
    if (this.spring) this.spring.destroy();
  }



  /**
   * Spring entered resting poition
   */
  onSpringAtRest(spring) {
    if (this.config.debug) console.log("onSpringAtRest");
    // Activate re-chaos flag after some time
    // if (this.onRestTimeout) clearTimeout(this.onRestTimeout);
    // this.onRestTimeout = setTimeout(onExtendedRest, this.config.spring.extendedRestDelay * 1000); // when would a user normally scroll "again", while it should "feel" the same scroll?
  }

  /**
   * Spring is in extended rest  (long time)
   */
  onExtendedRest() {
    if (this.config.debug) console.log("onExtendedRest");
    // if (this.spring.isAtRest()) this.shouldReChaos = true;
  }

  /**
   * Spring in action
   */
  onSpringUpdate(spring) {
    this.pos = spring.getCurrentValue();
    // console.log(val);
    // this.position.y = this.position.y * val;

    // const path = calcPath(this.srcPath, this.dstPath, val);
    // if (this.paths.length >= this.config.path.paths) this.paths.shift();
    // this.paths.push(path);

    // this.resetCanvas();
    // this.drawBackground();
    // this.drawPaths();
  }

  attract(x, y, endval = 1) {
    this.attractTo = { x, y };
    this.spring.setEndValue(endval);
    this.isAttracted = true;
  }

  unattract() {
    if (!this.isAttracted) return;
    this.spring.setEndValue(0);
    this.isAttracted = false;
  }


  // pullSpring(pos) {
  //   if (typeof pos === 'undefined') pos = 1;
  //   const val = this.spring.getCurrentValue();
  //   console.log(val, pos, val === pos);
  //   if (val === pos) pos = Math.abs(val-pos);

  //   this.spring.setEndValue(pos);
  // }


  center(arr) {
    const x = arr.map (xy => xy[0]);
    const y = arr.map (xy => xy[1]);
    const cx = (Math.min (...x) + Math.max (...x)) / 2;
    const cy = (Math.min (...y) + Math.max (...y)) / 2;
    return [cx, cy];
  }


  draw() {
    if (typeof this.canvasWidth === 'undefined') return;  // not yet initialised?

    this.ctx.beginPath();
    // this.ctx.moveTo (this.points[0][0], this.points[0][1]);
    this.points.forEach(p => this.ctx.lineTo(p[0], p[1]));
    this.ctx.closePath();


    const pos = Math.abs(Math.sin(this.points[0][0] * Math.PI / 180)); // * (this.seedX > 0.5 ? 1 : -1));
    // this.color++; if (this.color >= this.config.particles.fill.length) this.color = 0;  // TODO: HACK
    const fromColor = this.config.particles.fill[this.color];
    const toColor = this.config.particles.toColor; //this.config.particles.fill[0];
    const color = rebound.MathUtil.interpolateColor(pos, fromColor, toColor);

    this.ctx.fillStyle = color + this.config.particles.opacity;
    this.ctx.fill();

    // Stroke
    if (this.config.particles.stroke.color) {
      this.ctx.strokeStyle = this.config.particles.stroke.color + this.config.particles.opacity;
      this.ctx.lineWidth = this.config.particles.stroke.width;
      this.ctx.stroke();
    }
  }

  // rotate(x, y, centerX, centerY, )
  center(arr) {
    const x = arr.map (xy => xy[0]);
    const y = arr.map (xy => xy[1]);
    const cx = (Math.min (...x) + Math.max (...x)) / 2;
    const cy = (Math.min (...y) + Math.max (...y)) / 2;
    return [cx, cy];
  }


  update() {
    if (typeof this.canvasWidth === 'undefined') return;  // not yet initialised?

    // Size
    //this.size += (Math.random());
    //if (this.size > 20) this.size -= 2;


    // Set position
    if (!this.isAttracted) {
      // TODO: continues movement MODE
      const speed = 5;
      this.position.x -= this.seedX * speed;
      this.position.y += this.seedY * speed; //Math.floor(Math.random() * 2) - 1;
    }

    // Position bounderies
    if (this.position.x > (this.canvasWidth + this.size/2)) this.position.x = 0 - this.size;
    if (this.position.x < (0 - this.size)) this.position.x = this.canvasWidth + this.size / 2;
    if (this.position.y > (this.canvasHeight + this.size/2)) this.position.y = 0 - this.size;
    if (this.position.y < (0 - this.size)) this.position.y = this.canvasHeight + this.size / 2;





    // const { x, y } = {x: 100, y: 200 };
    // TODO: in-efficient,  override when not animating attract
    const toX = this.attractTo.x;// + ((this.seedX - 0.5) * 100);
    const toY = this.attractTo.y;// + ((this.seedY - 0.5) * 100)
    const x = rebound.MathUtil.mapValueInRange(this.pos, 0, 1, this.position.x, toX);
    const y = rebound.MathUtil.mapValueInRange(this.pos, 0, 1, this.position.y, toY);

    // generate shape
    let points = Array.from(Array(this.sides)).map((_, i) => {
      let _x = x + this.size * Math.cos(i * 2 * Math.PI / this.sides);
      let _y = y + this.size * Math.sin(i * 2 * Math.PI / this.sides);
      if (i%3 === 0) {  // strech first point, to make it "polygon" styled
        _x += this.seedX * this.config.particles.polystrechX;
        _y += this.seedY * this.config.particles.polystrechY;
      }
      return [_x, _y];
    });


    // ** rotate polygon around its center
    // const angle = this.seedX * (180 + points[0][1]%360 * (this.seedX > 0.5 ? 1 : -1));
    const angle = (points[0][0]%360 * (this.seedX > 0.5 ? 1 : -1));
    const a = angle * Math.PI / 180;
    const [centerX, centerY] = this.center(points);
    points = points.map(p => {
      return [
        (p[0] - centerX) * Math.cos(a) - (p[1] - centerY) * Math.sin(a) + centerX,
        (p[0] - centerX) * Math.sin(a) + (p[1] - centerY) * Math.cos(a) + centerY
      ];
    });

    this.points = points;
    // this.life--;
  }

  setCanvasSize(width, height) {
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.position = {x: Math.floor(this.seedX * width), y: Math.floor(this.seedY * height)};
  }

/*

    constructor(pos, vel, col, life, shape, size, opacity, force) {
        this.pos = pos; // Position
        this.vel = vel; // Velocity
        this.col = col; // Color
        this.life = life; // Lifetime
        this.shape = shape; // Shape p5.js 2d only supports rect and ellipse
        this.size = size; // size
        this.opacity = opacity; // transparency
        this.force = force; // additional property for adding gravity
    }
 */
}