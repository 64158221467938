import rebound from 'rebound';
import Particle from './particle.js';

export default class Particles {

  constructor(ctx, config, width, height) {
    this.ctx = ctx;
    this.width = width;
    this.height = height;

    const { count, sides } = config.particles;
    this.count = count;
    this.sides = sides;

    this.config = config;
    // const { tension, friction } = config.spring;
    // this.tension = tension;
    // this.friction = friction;

    // this.pos = pos;
    // this.life = life;
    // this.origLife = life;
    // this.size = size;
    // this.opacity = opacity;
    // this.force = force;
    // this.generationSpeed = generationSpeed;


    this.init();
  }

  init() {
    this.particles = [];
    const springSystem = new rebound.SpringSystem();

    for (let i=0; i < this.count; i++) {
      const particle = new Particle(this.ctx, this.config, i%4 + 3, springSystem);
      this.particles.push(particle);
    }
  }

  draw() {
    this.resetCanvas();
    this.particles.forEach(p => {
      // p.update();
      p.draw();
    });
  }


  update() {
    this.particles.forEach(p => {
      p.update();
    });

    // this.particles = this.particles.filter(p => !p.isDead());
    // if (frameCount % this.generationSpeed === 0) {
    //   this.init();
    // }
  }

  // bang(x, y) {
  //   this.particles.forEach(p => p.pullSpring());
  // }

  attract(x, y, force, gravity) {
    if (isNaN(force)) force = 0.2;
    if (isNaN(gravity)) gravity = 1;

    const count = this.particles.length;
    const shuffled = [].concat(this.particles);

    shuffled.sort(() => force - Math.random());
    shuffled.forEach((p, i) => {
      if ((i)/count < force) p.attract(x, y, gravity);
    });
  }

  unattract() {
    this.particles.forEach(p => p.unattract());
  }

  resetCanvas() {
    this.ctx.clearRect(0, 0, this.width, this.height);
  }

  setCanvasSize(width, height) {
    this.width = width;
    this.height = height;

    this.particles.forEach(p => {
      p.setCanvasSize(width, height);
    });


  }
}
